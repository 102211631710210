import { FC, memo } from 'react'

import { Text } from '@cash-me/react-components'

import { Section } from 'components/general-styles'
import { Grid, Col } from 'components/grid'

import * as S from './styles'

const Utility: FC = memo(() => (
  <>
    <Section $bg="contrast">
      <Grid col={{ phone: 1, tablet: 12 }}>
        <Col col={{ tablet: 10, desktop: 8 }} start={{ tablet: 2, desktop: 3 }}>
          <S.Text size="heading3" weight="medium" color="var(--colors-blue-text-strong)">
            Com o empréstimo para condomínio, você reduz os custos do condomínio ao longo do tempo e valoriza o imóvel
            dos proprietários.
          </S.Text>
        </Col>
      </Grid>
    </Section>
    <S.Section $bg="medium">
      <Grid col={{ phone: 1, tablet: 1 }}>
        <Col col={{ desktop: 10 }} start={{ desktop: 2 }}>
          <S.Infos>
            <Text className="subtitle" size="caption" weight="medium" uppercase>
              Como usar o crédito
            </Text>
            <Text as="h2" size="heading3" weight="medium">
              Veja algumas <span>maneiras de usar o crédito para condomínio</span>
            </Text>
          </S.Infos>
        </Col>
      </Grid>
    </S.Section>
  </>
))

Utility.displayName = 'Utility'

export default Utility
